import "./program-finder.scss";

import { ReactNode, useState, MouseEvent } from "react";
import * as Checkbox from "@radix-ui/react-checkbox";
import * as Popover from "@radix-ui/react-popover";

import { CtaLink } from "design-system/components/primitives/cta-link/cta-link";
import { Theme } from "design-system/utils/theme";
import { EditAttributes } from "design-system/types/types";
import { Icon } from "design-system/components/icons/icon";

export interface ProgramFinderProps {
  title?: string;
  subtitle?: string;
  searchPlaceholder?: string;
  searchCTAText?: string;
  footerCTA?: ReactNode;
  theme?: Theme;
  topicsList?: Array<string>;
  formatsList?: Array<string>;
  programFinderUrl?: string;

  editAttributes?: {
    title?: EditAttributes;
    subtitle?: EditAttributes;
  };
}

interface FormState {
  query: string;
  filters: Record<string, Array<string>>;
}

const FILTERS = {
  TOPIC: "Topic",
  FORMAT: "Format",
};

/**
 * A standalone entry point into the [program finder](/iframe.html?args=&id=exed-program-finder--story&viewMode=story) results
 */
export function ProgramFinder({
  title,
  subtitle,
  searchPlaceholder,
  searchCTAText,
  footerCTA,
  theme = "dark",
  topicsList,
  formatsList,
  programFinderUrl,
  editAttributes,
}: ProgramFinderProps) {
  const [formValues, setFormValues] = useState<FormState>({
    query: "",
    filters: {
      [FILTERS.TOPIC]: [],
      [FILTERS.FORMAT]: [],
    },
  });

  const selectedTopicsCount = formValues.filters[FILTERS.TOPIC]?.length;
  const selectedFormatsCount = formValues.filters[FILTERS.FORMAT]?.length;

  const onSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const updatedSearchParams = new URLSearchParams(window.location.search);
    if (formValues?.query) {
      updatedSearchParams.set("q", formValues?.query);
    }
    if (
      formValues?.filters[FILTERS.TOPIC]?.length &&
      formValues?.filters.Topic
    ) {
      updatedSearchParams.set("topic", formValues?.filters.Topic.join(","));
    }
    if (
      formValues?.filters[FILTERS.FORMAT]?.length &&
      formValues?.filters.Format
    ) {
      updatedSearchParams.set("format", formValues?.filters.Format.join(","));
    }
    programFinderUrl += `?${updatedSearchParams.toString()}`;

    if (programFinderUrl) {
      window.location.href = programFinderUrl;
    }
  };

  const onCheckedChange = (
    filter: keyof typeof FILTERS,
    checked: Checkbox.CheckedState,
    value: string,
  ) => {
    const oldTopicFilters = formValues.filters[FILTERS[filter]];

    if (oldTopicFilters) {
      const newTopicFilters = checked
        ? oldTopicFilters.concat(value)
        : oldTopicFilters.filter((filter) => filter !== value);

      setFormValues({
        ...formValues,
        filters: {
          ...formValues.filters,
          [FILTERS[filter]]: newTopicFilters,
        },
      });
    }
  };

  return (
    <div
      className="hbs-global-align-full hbs-program-finder"
      data-theme={theme}
    >
      <div className="hbs-program-finder__inner">
        <div className="hbs-program-finder__hgroup">
          {title && (
            <h2
              className="hbs-program-finder__title"
              {...editAttributes?.title}
            >
              {title}
            </h2>
          )}
          {subtitle && (
            <p
              className="hbs-program-finder__subtitle"
              {...editAttributes?.subtitle}
            >
              {subtitle}
            </p>
          )}
        </div>

        <form className="hbs-program-finder__form">
          <div className="hbs-program-finder__input">
            <Icon name="search" className="hbs-program-finder__input-icon" />
            <input
              type="text"
              aria-label="Search by keyword, e.g. “leadership"
              placeholder={
                searchPlaceholder || "Search by keyword, e.g. “leadership”"
              }
              onChange={(e) => {
                setFormValues((old) => ({
                  ...old,
                  query: e.target.value,
                }));
              }}
            />
          </div>

          <div className="hbs-program-finder__dropdown">
            <Popover.Root>
              <Popover.Trigger className="hbs-program-finder__dropdown-trigger">
                {selectedTopicsCount ? (
                  <span className="hbs-program-finder__dropdown-text">
                    {selectedTopicsCount} topic
                    {selectedTopicsCount > 1 ? "s" : ""} selected
                  </span>
                ) : (
                  <p>Any topic</p>
                )}
                <Icon
                  name="caretDown"
                  className="hbs-program-finder__dropdown-caret"
                />
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content
                  className="hbs-program-finder__dropdown-content"
                  sideOffset={8}
                  align="start"
                  avoidCollisions={false}
                  asChild
                >
                  {topicsList && topicsList.length > 0 && (
                    <ul>
                      {topicsList?.map((item, i) => (
                        <li
                          key={i}
                          className="hbs-program-finder__dropdown-item"
                        >
                          <Checkbox.Root
                            id={item}
                            className="hbs-program-finder__checkbox"
                            checked={formValues.filters[
                              FILTERS.TOPIC
                            ]?.includes(item)}
                            onCheckedChange={(checked) =>
                              onCheckedChange("TOPIC", checked, item)
                            }
                          >
                            <Checkbox.Indicator>
                              <Icon
                                aria-hidden="true"
                                name="check"
                                className="hbs-program-finder__checkbox-icon"
                              />
                            </Checkbox.Indicator>
                            <span className="hbs-global-visually-hidden">{`Filter by ${item}`}</span>
                          </Checkbox.Root>
                          <label
                            aria-hidden="true"
                            htmlFor={item}
                            className="hbs-program-finder__checkbox-label"
                          >
                            {item}
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          </div>

          <div className="hbs-program-finder__dropdown">
            <Popover.Root>
              <Popover.Trigger className="hbs-program-finder__dropdown-trigger">
                {selectedFormatsCount ? (
                  <span className="hbs-program-finder__dropdown-text">
                    {selectedFormatsCount} format
                    {selectedFormatsCount > 1 ? "s" : ""} selected
                  </span>
                ) : (
                  <span>Any format</span>
                )}
                <Icon
                  name="caretDown"
                  className="hbs-program-finder__dropdown-caret"
                />
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content
                  className="hbs-program-finder__dropdown-content"
                  sideOffset={8}
                  align="start"
                  avoidCollisions={false}
                  asChild
                >
                  {formatsList && formatsList.length > 0 && (
                    <ul>
                      {formatsList?.map((item, i) => (
                        <li
                          key={i}
                          className="hbs-program-finder__dropdown-item"
                        >
                          <Checkbox.Root
                            id={item}
                            className="hbs-program-finder__checkbox"
                            checked={formValues.filters[
                              FILTERS.FORMAT
                            ]?.includes(item)}
                            onCheckedChange={(checked) =>
                              onCheckedChange("FORMAT", checked, item)
                            }
                          >
                            <Checkbox.Indicator>
                              <Icon
                                name="check"
                                className="hbs-program-finder__checkbox-icon"
                              />
                            </Checkbox.Indicator>
                          </Checkbox.Root>
                          <label
                            htmlFor={item}
                            className="hbs-program-finder__checkbox-label"
                          >
                            {item}
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          </div>

          <CtaLink
            className="hbs-program-finder__submit"
            type={"primary-button"}
            onClick={onSubmit}
          >
            {searchCTAText ? searchCTAText : "Browse Programs"}
          </CtaLink>
        </form>

        {footerCTA && (
          <div className="hbs-program-finder__footer">{footerCTA}</div>
        )}
      </div>
    </div>
  );
}
