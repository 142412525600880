import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { ProgramFinderEntry } from "frontend/contentful/schema/blocks";
import { useContentful } from "frontend/hooks/use-contentful";
import { RenderContentfulRichText } from "frontend/contentful/components/utils/render-contentful-rich-text";
import {
  ProgramFinder,
  ProgramFinderProps,
} from "design-system/components/blocks/program-finder/program-finder";
import { useSearch } from "frontend/hooks/use-search";
import { SearchQueryIndex } from "frontend/types";

export const ContentfulProgramFinder =
  createContentfulComponent<ProgramFinderEntry>(
    ({ entry, createEditAttributes }) => {
      const { getEntry, getEntryUrl } = useContentful();

      const props: ProgramFinderProps = {
        title: entry.fields.title,
        subtitle: entry.fields.subtitle,
        searchPlaceholder: entry.fields.searchPlaceholder,
        footerCTA: entry.fields.footerText ? (
          <RenderContentfulRichText
            document={entry.fields.footerText}
            disableParagraphs
          />
        ) : undefined,
        theme: entry.fields.theme,
        programFinderUrl: getEntryUrl(entry.fields.programFinderLink),

        editAttributes: {
          title: createEditAttributes({ entry, fieldId: "title" }),
          subtitle: createEditAttributes({ entry, fieldId: "subtitle" }),
        },
      };

      const contentSourceEntry = getEntry(entry.fields.contentSource);
      const searchQueryParams = {
        index: (contentSourceEntry?.fields.index ||
          "modern") as SearchQueryIndex,
        query: contentSourceEntry?.fields.query,
        size: parseInt(contentSourceEntry?.fields.limit || "100"),
        facets: ["topic", "format"],
      };
      const { data } = useSearch(searchQueryParams);

      props.topicsList = data?.facets?.topic?.map((facet) => facet.name).sort();
      props.formatsList = data?.facets?.format
        ?.map((facet) => facet.name)
        .sort();

      return <ProgramFinder {...props} />;
    },
  );
